import { AdminRole } from '@/generated/typing';
import type { RolesMap } from './user-access';
import { Feature, Section } from './user-access';

const userAccess: RolesMap = {
  [AdminRole.ClaimsLeader]: {
    [Section.ALL_SECTIONS]: true,
    [Feature.DELETE_CONTRACT]: true,
    [Feature.UPDATE_SUBSCRIPTION_SERVICE_FIELDS]: true,
    [Feature.UPDATE_SUBSCRIPTION_PAYMENT_METHOD]: true,
    [Feature.UPDATE_SUBSCRIPTION_CUSTOM_PAYMENT_METHOD]: true,
    [Feature.VOID_SUBSCRIPTION]: true,
    [Feature.REFUND_SUBSCRIPTION]: true,
    [Feature.UPDATE_DEALER_ROLE]: true,
    [Feature.DELETE_DEALER_FROM_STORE]: true,
    [Feature.DELETE_ADMIN]: true,
    [Feature.UPDATE_ADMIN_ROLE]: true,
    [Feature.UPDATE_AUTO_MAIL_BILLING_INFORMATIONS]: true,
    [Feature.UPDATE_AUTO_GENERATION_BILLING_INFORMATIONS]: true,
    [Feature.OVERRIDE_COMPANY_NAME]: true,
    [Feature.UPDATE_AUTO_OPTIMISATION_BILLING_INFORMATIONS]: true,
    [Feature.GET_SUBSCRIPTION_PAYMENT_MEAN]: true,
    [Feature.UPDATE_SUBSCRIPTION_CONTRACT]: true,
    [Feature.CREATE_DATA_PROVIDER]: true,
    [Feature.CREATE_SERVICE]: true,
    [Feature.CREATE_PRODUCT]: true,
    [Feature.CREATE_SERVICE_ACCOUNT]: true,
    [Feature.CREATE_STORE]: true,
    [Feature.CREATE_DOCUMENT_TEMPLATE]: true,
    [Feature.CREATE_MAIL_TEMPLATE]: true,
    [Feature.CREATE_PROVIDER]: true,
    [Feature.CREATE_CONTRACT]: true,
    [Feature.IBAN_BILLING_INFORMATIONS]: true,
    [Feature.CANCEL_SUBSCRIPTION_CONTRACT]: true,
    [Feature.DELETE_STORE_INVOICE]: true,
    [Feature.WEBHOOKS]: true,
    [Feature.UPDATE_STORE_INVOICE_STATUS]: true,
    [Feature.GET_ALL_INVOICES]: true,
    [Feature.UPDATE_BENEFICIARIES]: true,
    [Feature.SET_SUBSCRIPTION_EXTERNAL_ID]: true,
    [Feature.EXPORT_SUBSCRIPTIONS]: true,
    [Feature.GENERATE_MEMBERSHIP_AGREEMENT]: true,
    [Feature.SEND_DOCUMENT]: true,
    [Feature.UPDATE_CUSTOMER]: true,
    [Feature.DELETE_CLAIM]: false,
  },
  [AdminRole.ClaimsManager]: {
    [Section.DUPLICATE_CLAIM_FIELDS]: false,
    [Feature.UPDATE_CUSTOMER]: true,
    [Feature.UPDATE_BENEFICIARIES]: true,
    [Feature.GENERATE_MEMBERSHIP_AGREEMENT]: true,
    [Feature.SEND_DOCUMENT]: true,
    [Feature.UPDATE_SUBSCRIPTION_PAYMENT_METHOD]: true,
    [Feature.UPDATE_SUBSCRIPTION_SERVICE_FIELDS]: true,
    [Section.CHILDREN]: true,
    [Section.ACCOUNT]: true,
    [Section.ACCOUNT_UPDATE]: true,
    [Section.ACCOUNT_SETTINGS]: true,
    [Section.ADMIN]: true,
    [Section.API]: true,
    [Section.ADMINISTRATION]: true,
    [Section.BILLING_INFORMATIONS]: true,
    [Section.CLAIMS]: true,
    [Section.CLIENTS]: true,
    [Section.COMPENSATION]: true,
    [Section.COMPANIES]: true,
    [Section.COMPANY]: true,
    [Section.CONTRACTS]: true,
    [Section.CONTRACTS_UPDATE]: true,
    [Section.CONTRACTS_MAIL_TEMPLATE_UPDATE]: true,
    [Section.CUSTOMER_EXTERNAL_ID]: true,
    [Section.CUSTOMER_GDA]: true,
    [Section.WEBSITES_MAIL_TEMPLATE_UPDATE]: true,
    [Section.CONTRACTS_DOCUMENT_TEMPLATES]: true,
    [Section.DASHBOARD]: true,
    [Section.DATA_PROVIDERS]: true,
    [Section.STORE_IMPORT_HISTORY]: true,
    [Section.STORE_INVOICES]: true,
    [Section.DOCUMENT_TEMPLATES]: true,
    [Section.MAIL_TEMPLATES]: true,
    [Section.PAYMENT_METHODS]: true,
    [Section.PRODUCTS]: true,
    [Section.PRODUCT_CONFIGURATION]: true,
    [Section.PRODUCTS_CONFIGURATION]: true,
    [Section.PRODUCTS_STORES]: true,
    [Section.PRODUCTS_COVERAGE]: true,
    [Section.PRODUCTS_CLAIMS]: false,
    [Section.PROVIDERS]: true,
    [Section.SALES_CHANNELS]: true,
    [Section.SERVICES]: true,
    [Section.SERVICES_ACCOUNTS]: true,
    [Section.STORE]: true,
    [Section.STORES]: true,
    [Section.SUBSCRIBE]: true,
    [Section.SUBSCRIPTIONS]: true,
    [Section.SUBSCRIPTION_EXTERNAL_ID]: true,
    [Section.SUBSCRIPTION_PAYMENT_MEAN]: true,
    [Section.DEALERS]: true,
    [Section.ADMINS]: false,
    [Section.WEBSITES]: true,
    [Section.UPDATE_SUBSCRIPTION_CONTRACT]: true,
    [Section.PROVIDER_SUBSCRIPTIONS]: true,
    [Section.SUBSCRIPTION_PROCEDURES]: true,
    [Feature.DELETE_CLAIM]: false,
    [Section.CLAIM_PROPERTY_MAPPINGS]: false,
    [Section.CUSTOMER_CLAIMS]: true,
  },
};

export const adminHasAccessTo = (
  role: AdminRole,
  item: Section | Feature,
  specificCondition: boolean = true,
): boolean => {
  if (specificCondition !== undefined && !specificCondition) {
    return false;
  }

  if (role === AdminRole.SuperAdmin) {
    return true;
  }

  if (userAccess[role]) {
    if (
      Object.values(Section).includes(item as Section) &&
      userAccess[role]?.[Section.ALL_SECTIONS]
    ) {
      return true;
    }

    if (
      Object.values(Feature).includes(item as Feature) &&
      userAccess[role]?.[Feature.ALL_FEATURES]
    ) {
      return true;
    }
  }

  return !!userAccess[role]?.[item];
};
